import React, { Component } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import { GatsbyImage } from "gatsby-plugin-image";
import FlexibleContentFeatured from "components/FlexibleContent/FlexibleContentFeatured"
import SidebarBlock from "components/shared/SidebarBlock"
import Article from "components/shared/Article"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import DateFormat from "components/shared/DateFormat"
import SidebarGoogleAds from "components/shared/SidebarGoogleAds"
import SidebarTempAds from "components/shared/SidebarTempAds"
import SidebarAds from "components/shared/SidebarAds"
library.add(fas)


const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
    color: ${props => props.theme.colors.secondary};
`

const ContainerStyled = styled(Container)`
    .content {
        @media ${media.lg} {
            width: 740px;
            max-width: calc(100% - 330px);
        }
    }

    .sidebar {
        width: 100%;
        @media ${media.lg} {
            max-width: 330px;
        }
    }
`

class PostLayout extends Component {
    render() {
        const { data } = this.props
        const shareUrl = `${process.env.GATSBY_FRONTEND_URL}/news/${data.slug}`
        console.log(data)
        console.log(data.acfAdsBlocks)
        console.log(Object.values(this.props.data.acfAdsBlocks).every(
            value => value === null))
        return (
            <ContainerStyled>
                <Article>
                    <header>

                        <p className="breadcrumb">
                            <Link to="/">
                                Home
                            </Link> /&nbsp;
                            <Link to="/news/">
                                News
                            </Link> /&nbsp;
                            <span>
                                Article
                            </span>
                        </p>

                        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />

                    </header>
                    <Row>
                        <Col xs={12} lg="auto" className="content">

                            {data.featuredImage.node.localFile &&
                                <figure className="hero-image">
                                    <GatsbyImage
                                        image={data.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                                        alt={data.featuredImage.node.altText} />
                                    {data.featuredImage.node.caption &&
                                        <figcaption dangerouslySetInnerHTML={{ __html: data.featuredImage.node.caption }} />
                                    }
                                </figure>
                            }

                            <Row>
                                <Col md="3">
                                    <Container>
                                        <Row>
                                            <Col xs={6} md={12}>
                                            <p className="date mb-4"><DateFormat date={data.date} /></p>
                                            </Col>
                                            <Col xs={6} md={12}>
                                                <div className="share">
                                                    <p className="mb-2 mr-4 d-inline-block d-md-block">SHARE</p>
                                                    <FacebookShareButton className="share-button d-inline-block mr-4" url={shareUrl}><FontAwesomeIconStyled icon={faFacebookF} /></FacebookShareButton>
                                                    <TwitterShareButton className="share-button d-inline-block" url={shareUrl}><FontAwesomeIconStyled icon={faTwitter} /></TwitterShareButton>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col md="9">
                                    {/*loop through the content blocks*/}
                                    <FlexibleContentFeatured blocks={data.acfTwoColumnFlexibleContent.twoColumnBlocks} />
                                </Col>

                            </Row>
                        </Col>
                        <Col xs={12} lg="auto" className="sidebar">
                            <aside>
                                <Row className="justify-content-center">
                                    <Col xs={{ size: 12, order: 2 }} md={{ order: 1 }} className="pb-3 pb-md-0 px-1 px-lg-0 d-flex justify-content-center flex-wrap">
                                        {/* <SidebarGoogleAds googleAdsSidebar={this.props.googleAdsSidebar} /> */}
                                        <SidebarAds data={data.acfAdsBlocks} googleAdsSidebar={this.props.googleAdsSidebar} />
                                    </Col>
                                    <Col xs={{ size: 12, order: 1}} md={{ order: 2 }}>
                                        <Row>
                                        {data.acfTwoColumnFlexibleContent.sidebar && data.acfTwoColumnFlexibleContent.sidebar.map((node, i) => {
                                            switch (node.acfSidebarBlocks.sidebarBlockType[0].__typename) {
                                                case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_ImageLink":
                                                    return(
                                                        <Col xs={6} md={4} lg={12} className="pb-3 pb-md-0 px-lg-0" key={i}>
                                                            <SidebarBlock id={node.databaseId} margin={true} />
                                                        </Col>
                                                    )

                                                case "WpSidebarBlock_Acfsidebarblocks_SidebarBlockType_Rolex":
                                                    return(
                                                        <Col xs={6} md={4} lg={12} className="d-none d-lg-block pb-3 pb-md-0 px-lg-0" key={i}>
                                                            <SidebarBlock id={node.databaseId} margin={true} />
                                                        </Col>
                                                    )
                                            
                                                default:
                                                    return "";
                                            }
                                        })}
                                        </Row>
                                    </Col>
                                </Row>   
                            </aside>
                        </Col>
                    </Row>
                </Article>
            </ContainerStyled>
        );
    }
}


export default PostLayout