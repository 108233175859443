import React, { useState, useEffect } from "react"
import mpu1 from "images/ads/Chevron_TCC22_Website_300x250.jpg"
// import mpu2 from "images/ads/ANA22-digital-banner-ad-300x250.jpg"
import doublempu1 from "images/ads/Chevron_TCC22_Website_300x600.jpg"
// import doublempu2 from "images/ads/ANA22-digital-banner-ad-300x600.jpg"

const SidebarTempAds = () => {
  const [ad, setAd] = useState(0)
  const ads = [
    {
      mpu: mpu1,
      double_mpu: doublempu1,
      url: "https://www.chevron.com"
    }
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      const max = ads.length - 1
      setAd(ad => ad + 1 > max ? 0 : ad + 1)
    }, 10000);

    return () => clearInterval(interval);
  }, [])

  return(
    <div className="pb-2" style={{ marginLeft: "-15px" }}>
      <a href={ads[ad].url} target="_blank" rel="noopener noreferrer" className="pb-2">
        <img src={ads[ad].mpu} alt="" />
      </a>
      <a href={ads[ad].url} target="_blank" rel="noopener noreferrer">
        <img src={ads[ad].double_mpu} alt="" />
      </a>
    </div>
  )
}

export default SidebarTempAds
